import React, { useContext, useState } from 'react';
import { client } from 'shared';
import { Grid, styled } from '@mui/material';
import { Checkbox, Colors, SecondaryButton, PrimaryButton, Text } from 'library';

import Row from './Row';
import Memo from './Memo';
import { BannedWorkerModalContext } from './BannedWorkerModalContext';

import type { BusinessLocationBannedWorker } from 'models';

interface ILocationCheckbox {
    [key: string]: boolean;
}

interface IBusinessOutlawRequest {
    business_id: number;
    business_outlaw_id: number;
    business_outlaw_locations: ILocationParam[];
    memo?: string | null;
}

interface ILocationParam {
    business_location_id: string;
    business_outlaw_id: number;
    memo?: string | null;
    is_business_outlaw: boolean;
}

export default function LocationsList() {
    const { isAdmin, business, businessLocationId, provider, locations, setLocations, setError, onSuccess, onClose } =
        useContext(BannedWorkerModalContext);

    const [locationCheckboxes, setLocationCheckboxes] = useState(getCheckboxes(false, false));
    const [businessMemo, setBusinessMemo] = useState<string | null>(locations?.[0]?.business_memo ?? null);
    const [locationMemos, setLocationMemos] =
        useState<Record<string, { memo: string | null; dirty: boolean }>>(getMemos());
    const [dirtyMemo, setDirtyMemo] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    function setCheckbox(checked: boolean, id: string) {
        setLocationCheckboxes({ ...locationCheckboxes, [`${id}`]: checked });
        setLocationMemos({ ...locationMemos, [`${id}`]: { memo: locationMemos[`${id}`].memo, dirty: true } });
    }

    function setLocationMemo(id: number, memo: string) {
        setLocationMemos({ ...locationMemos, [`${id}`]: { memo, dirty: true } });
        setDirtyMemo(true);
    }

    function getCheckboxes(checked: boolean = true, useChecked: boolean = true) {
        const checkboxes: ILocationCheckbox = {};
        locations.map(
            (location: BusinessLocationBannedWorker) =>
                (checkboxes[`${location.id}`] = useChecked ? checked : location.is_business_outlaw),
        );
        return checkboxes;
    }

    function getMemos(newLocations: BusinessLocationBannedWorker[] = locations) {
        const memos: Record<string, { memo: string | null; dirty: boolean }> = {};
        newLocations.map(
            (location: BusinessLocationBannedWorker) =>
                (memos[`${location.id}`] = { memo: location.memo ?? null, dirty: false }),
        );
        return memos;
    }

    function onClick() {
        setSubmitting(true);

        const requestParams: IBusinessOutlawRequest = {
            ...{
                business_id: business.id,
                business_outlaw_id: provider.id,
                business_outlaw_locations: [],
            },
            ...(isAdmin ? { memo: businessMemo ?? null } : {}),
        };

        requestParams.business_outlaw_locations = Object.entries(locationCheckboxes).map(
            (locationCheckbox: [string, boolean]) => ({
                ...{
                    business_location_id: locationCheckbox[0],
                    business_outlaw_id: provider.id,
                    is_business_outlaw: locationCheckbox[1],
                },
                ...(isAdmin ? { memo: locationMemos[locationCheckbox[0]]?.memo ?? null } : {}),
            }),
        );

        const url = isAdmin ? 'internal/business-outlaw/update-locations/' : 'api/business-outlaws/update-locations/';
        client(url, {
            method: 'POST',
            body: requestParams,
        })
            .then((response: BusinessLocationBannedWorker[]) => {
                setLocations(response);
                setLocationMemos(getMemos(response));
                setBusinessMemo(response?.[0]?.business_memo ?? null);
                onClose();
                onSuccess(response);
            })
            .catch(() => setError(true))
            .finally(() => setSubmitting(false));
    }

    return (
        <>
            <InnerDialog container direction="column">
                <RowContainer>
                    <BusinessRow item>
                        <Text bold>{business.name}</Text>
                        <Checkbox
                            label="Add to all locations"
                            checked={Object.values(locationCheckboxes).every((value) => value)}
                            onChange={(checked: boolean) => {
                                setLocationCheckboxes(getCheckboxes(checked, true));
                                setDirtyMemo(true);
                            }}
                            style={{ width: 'unset' }}
                        />
                    </BusinessRow>
                    <Memo memo={businessMemo} dirty={dirtyMemo} setMemo={(_, memo) => setBusinessMemo(memo)} />
                </RowContainer>
                <Text bold>Locations:</Text>
                <LocationsGrid>
                    {locations.map((location: BusinessLocationBannedWorker) => (
                        <Row
                            key={location.id}
                            highlight={!!(businessLocationId === location.id)}
                            createdBy={location.business_outlaw_created_by}
                            location={location}
                            checked={locationCheckboxes[`${location.id}`]}
                            disabled={submitting}
                            setCheckbox={setCheckbox}
                            memo={locationMemos[`${location.id}`]}
                            setMemo={setLocationMemo}
                        />
                    ))}
                </LocationsGrid>
            </InnerDialog>
            <ButtonRow container direction="row">
                <SecondaryButton onClick={onClose} buttonStyle={{ width: 'unset', marginRight: 10 }}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton onClick={onClick} buttonStyle={{ width: 'unset' }} loading={submitting}>
                    Set
                </PrimaryButton>
            </ButtonRow>
        </>
    );
}

const InnerDialog = styled(Grid)({
    background: Colors.lightTurq,
    marginBottom: '1em',
    borderRadius: '10px',
    padding: '1em',
});

const RowContainer = styled('div')({
    paddingBottom: '1em',
});

const BusinessRow = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
});

const LocationsGrid = styled(Grid)({
    marginLeft: '1em',
});

const ButtonRow = styled(Grid)({
    display: 'flex',
    justifyContent: 'flex-end',
});
