import React, { useState } from 'react';
import Error from 'parent-portal/provider-reviews/components/Error';
import {
    BannedWorkerModalContext,
    InnerModalContent,
} from 'parent-portal/shared/UserActions/BanWorkerModal/components';

import type { BusinessLocationBannedWorker, BusinessWithBusinessOutlaws, LeanUser } from 'models';

export function OutlawModalBody({ business, user }: { business: BusinessWithBusinessOutlaws; user: LeanUser }) {
    const [locations, setLocations] = useState<BusinessLocationBannedWorker[]>(business.business_locations);
    const [error, setError] = useState(false);

    return (
        <div style={{ paddingBottom: 20 }}>
            {!user || !business || error ? (
                <Error />
            ) : (
                <BannedWorkerModalContext.Provider
                    value={{
                        isAdmin: true,
                        business,
                        provider: {
                            id: user.id,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            profile_picture: { image: '' },
                        },
                        emptyLocationList: business.business_locations.length === 0,
                        locations,
                        setLocations,
                        setError,
                        onSuccess: () => {},
                        onClose: () => {},
                    }}
                >
                    <InnerModalContent />
                </BannedWorkerModalContext.Provider>
            )}
        </div>
    );
}
