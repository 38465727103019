import React, { useState } from 'react';
import { Text, Colors, TextButton, OutlinedTextInput, PrimaryButton, Checkbox } from 'library';
import { PastShift } from '../models';
import { styled, Grid } from '@mui/material';
import { format12HourTime, formatShortMonthDayYear, toISOLocal } from 'shared/Dates';
import { locationAddress } from './utils';
import { differenceInMinutes } from 'date-fns';
import { client, consoleLogInDev } from 'shared';

export default function ShiftCard({ shift, onEditDone }: { shift: PastShift; onEditDone: () => void }) {
    const [isEditing, setIsEditing] = useState(false);
    const [clockIn, setClockIn] = useState('');
    const [clockOut, setClockOut] = useState('');
    const [breakStart, setBreakStart] = useState('');
    const [breakEnd, setBreakEnd] = useState('');
    const [updatePayStub, setUpdatePayStub] = useState(false);
    const [updateInvoice, setUpdateInvoice] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function newDateNoSecondsOrMilliseconds(dateString: string) {
        const date = new Date(dateString);
        date.setMilliseconds(0);
        date.setSeconds(0);

        return date;
    }

    function submitUpdates() {
        setIsSubmitting(true);
        setErrorMessage('');
        client(`api/hour-report/${shift.id}/adjustment/`, {
            body: {
                clock_in: new Date(clockIn).toUTCString(),
                clock_out: new Date(clockOut).toUTCString(),
                break_start: breakStart ? new Date(breakStart).toUTCString() : undefined,
                break_end: breakEnd ? new Date(breakEnd).toUTCString() : undefined,
                update_pay_stub: updatePayStub,
                update_invoice: updateInvoice,
            },
        })
            .then(() => {
                onEditDone();
                onEditClose();
            })
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(error.message || 'There was an error adjusting the hour report.');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    function onEdit() {
        setClockIn(toISOLocal(newDateNoSecondsOrMilliseconds(shift.start)));
        setClockOut(toISOLocal(newDateNoSecondsOrMilliseconds(shift.end)));
        if (shift.break_start) {
            setBreakStart(toISOLocal(newDateNoSecondsOrMilliseconds(shift.break_start)));
        }
        if (shift.break_end) {
            setBreakEnd(toISOLocal(newDateNoSecondsOrMilliseconds(shift.break_end)));
        }
        setIsEditing(true);
    }

    function onEditClose() {
        setIsEditing(false);
        setUpdatePayStub(false);
        setUpdateInvoice(false);
    }

    function getEditContainer() {
        if (!isEditing) {
            return <></>;
        }

        const startEndDiffMinutes = differenceInMinutes(new Date(clockOut), new Date(clockIn));
        let breakMinutes = 0;
        if (breakStart && breakEnd) {
            breakMinutes = differenceInMinutes(new Date(breakEnd), new Date(breakStart));
        }
        const newHoursWorked = parseFloat(((startEndDiffMinutes - breakMinutes) / 60).toFixed(2));

        const newAmount = (shift.provider_rate || 0) * parseFloat(newHoursWorked.toFixed(2));
        const newFeeAmount = newAmount * 0.35;
        const isValid = newHoursWorked > 0.1;
        const validationMessage = !isValid
            ? 'The new hours worked are too small. Use the pay stub page to retract payments'
            : '';

        return (
            <Grid container>
                <TextContainer>
                    <OutlinedTextInput
                        type="datetime-local"
                        label="Clock in"
                        value={clockIn}
                        onChange={(e) => setClockIn(e.target.value)}
                    />
                </TextContainer>
                <TextContainer>
                    <OutlinedTextInput
                        type="datetime-local"
                        label="Clock Out"
                        value={clockOut}
                        onChange={(e) => setClockOut(e.target.value)}
                    />
                </TextContainer>
                <TextContainer>
                    <OutlinedTextInput
                        type="datetime-local"
                        label="Break Start"
                        value={breakStart}
                        onChange={(e) => setBreakStart(e.target.value)}
                    />
                </TextContainer>
                <TextContainer>
                    <OutlinedTextInput
                        type="datetime-local"
                        label="Break End"
                        value={breakEnd}
                        onChange={(e) => setBreakEnd(e.target.value)}
                    />
                </TextContainer>
                <TextContainer direction="row" justifyContent="space-between" container>
                    <Text variant="caption">New Amount: ${newAmount.toFixed(2)}</Text>
                    <Text variant="caption">New Fee Amount: ${newFeeAmount.toFixed(2)}</Text>
                </TextContainer>
                <TextContainer direction="row" justifyContent="space-between" container>
                    <Checkbox
                        label="Update paystub (pay worker or retract funds)"
                        checked={updatePayStub}
                        onChange={(val) => setUpdatePayStub(val)}
                    />
                    <Checkbox
                        label="Update invoice (add to invoice or refund)"
                        checked={updateInvoice}
                        onChange={(val) => setUpdateInvoice(val)}
                    />
                </TextContainer>
                <Grid>
                    <Text textStyle={{ color: Colors.error }}>{validationMessage || errorMessage}</Text>
                    <PrimaryButton disabled={!isValid || isSubmitting} loading={isSubmitting} onClick={submitUpdates}>
                        Submit
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    }

    const tardy =
        !!shift.appointment && differenceInMinutes(new Date(shift.appointment.start_date), new Date(shift.start)) < -5;
    return (
        <Container item container xs={12} md={3} direction="column" tardy={tardy}>
            <TitleContainer>
                <a href={`/adminperson/payments/hour-reports/${shift.id}`} target="_blank" rel="noreferrer">
                    <Text bold>
                        <u>{formatShortMonthDayYear(shift.start)}</u>
                    </Text>
                </a>
                <Text textStyle={{ border: `1px ${Colors.darkNavy} solid`, padding: '0 2px' }} variant="caption">
                    {shift.business_job_type?.replace('_', ' ')}
                </Text>
            </TitleContainer>
            {shift.late_minutes ? <Text variant="caption">(Reported late: {shift.late_minutes} minutes)</Text> : null}
            {shift.business_location ? (
                <>
                    <Text>{shift.business_location.name}</Text>
                    <Text variant="caption">{locationAddress(shift.business_location)}</Text>
                </>
            ) : null}
            {shift.appointment ? (
                <TextContainer direction="row" justifyContent="space-between" container item>
                    <Text variant="caption">Shift Start: {format12HourTime(shift.appointment.start_date)}</Text>
                    <Text variant="caption">Shift End: {format12HourTime(shift.appointment.end_date)}</Text>
                </TextContainer>
            ) : null}
            <TextContainer direction="row" justifyContent="space-between" container item>
                <Text variant="caption">Clock In: {format12HourTime(shift.start)}</Text>
                <Text variant="caption">Clock Out: {format12HourTime(shift.end)}</Text>
            </TextContainer>
            {shift.approved_break > 0 ? <Text variant="caption">Break: {shift.approved_break} minutes</Text> : null}
            <TextContainer direction="row" justifyContent="space-between" container item>
                <Text variant="caption">Hours Worked: {shift.hours_worked}</Text>
                {shift.provider_rate ? <Text variant="caption">Rate: ${shift.provider_rate}/hr</Text> : null}
            </TextContainer>
            <TextContainer direction="row" justifyContent="space-between" container item>
                <Text variant="caption">Payment: {shift.paid_amount ? `$${shift.paid_amount}` : 'Pending'}</Text>
                {shift.pay_stub_id && (
                    <TextButton buttonStyle={{ width: undefined }} onClick={isEditing ? onEditClose : onEdit}>
                        {isEditing ? 'Cancel' : 'Edit'}
                    </TextButton>
                )}
            </TextContainer>
            {getEditContainer()}
        </Container>
    );
}

const TitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const Container = styled(Grid)(({ tardy }: { tardy: boolean }) => ({
    backgroundColor: tardy ? Colors.lightRed : Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
}));

const TextContainer = styled(Grid)({
    marginTop: 10,
});
